import React, { Component } from 'react';
import Frame from "../../components/frame";

class Contact extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <Frame withHeader={true} withFooter={true}>
         <section className="banner_section pt_50 pb_50">
          <div className="container">
            <div className="banner_text text-center middle_text">
              <h1>FTINU Token </h1>
              <p>Current IMP Rate: <strong>800 BNB ($24)</strong></p>
              <div className="mt_10">
                <a target="_blank" className="grad_btn" href="#">Our Whitepaper <i className="fa fa-download" ></i></a>
              </div> 
            </div>
          </div>
        </section>
        <section className="pb_50">
          <div className="container">
            <div className="sm_container">
              <div className="row cus_row">
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <img className="Personal_icon" src="img/wallet.png" alt="" />
                    <h4>Your Wallet</h4>
                    <h5>TSJWxnuXzg.....HCNRw</h5>
                  </div>
                </div> 
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <img className="Personal_icon" src="img/balance.png" alt="" />
                    <h4>Your Balance</h4>
                    <h5>0.00 BNB/ 0.00000000 IMP</h5>
                  </div>  
                </div> 
              </div>
            </div>
          </div>
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="sm_container">
              <div className="row">
                <div className="col-md-12 buy_sell_box">
                  <h5 className="gradient_text">Buy Firsttron</h5>
                  <hr />
                  <div className="row">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">Firsttron Referal</label>
                        <input className="cus_input" type="text"  />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">Current Price (BNB)</label>
                        <input className="cus_input" type="text" value="800" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">FTINU</label>
                        <input className="cus_input" type="text" value="800" />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">Total Amount (BNB)</label>
                        <input className="cus_input" type="text" value="800" />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="grad_btn">Buy Firsttron</button>
                  </div>
                </div>
                
              </div>
            </div>
            </div>
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="all_heading text-center">
              <h2><span>Referral </span> Stake</h2>
            </div>
            <div className="sm_container">
              <div className="row cus_row">
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Available for Stake</h4>
                    <h5>-------</h5>
                    <button type="button" class="btn stake_p_a btn-outline-success btn-sm">Stake</button>
                  </div>
                </div> 
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Staking</h4>
                    <h5>-------</h5>
                  </div>  
                </div> 
              </div>
              <div className="text-center mt_20 mb_20">
                <button className="grad_btn">Withdrawal</button>
              </div>
            </div>
            <div className="sm_container">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Stake</th>
                      <th>ROI</th>
                      <th>Unstake</th>
                      <th>Compound</th>
                     </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>0</td>
                      <td><button type="button" class="btn btn-outline-success btn-sm btn-block">Unstake</button></td>
                      <td><button type="button" class="btn btn-outline-danger btn-sm btn-block">Compound</button></td>
                    </tr>
                    <tr>
                      <td>0</td>
                      <td>0</td>
                      <td><button type="button" class="btn btn-outline-success btn-sm btn-block">Unstake</button></td>
                      <td><button type="button" class="btn btn-outline-danger btn-sm btn-block">Compound</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="all_heading text-center">
              <h2><span>Firsttron </span> Token Details</h2>
            </div>
            <div className="sm_container">
              <p className="text-center mb-0">Firsttron Master Contract Address : <span className="theme_color">TW5eb1yydh2MWqxFqCqveu1N27cSiiHA82</span></p>
              <p className="text-center">Firsttron Token Contract Address : <span className="theme_color">TW5eb1yydh2MWqxFqCqveu1N27cSiiHA82</span></p>
              <div className="text-center mt_20 mb_20">
                <button data-toggle="modal" data-target="#Token-Distribution-Chart" className="grad_btn">Token Distribution Chart</button>
              </div>
            </div>
            <div className="sm_container">
              <div className="row">
                  <div className="col-md-6 co-lg-6 middle_text">
                    <ul class="progress-info">
                      <li>
                        <span><strong>Sold Token</strong></span>
                        <span>2088.47 IMP</span>
                      </li>
                      <li class="text-right">
                        <span><strong>PHASE 2</strong>
                        </span><span>3500 IMP</span>
                      </li>
                    </ul>
                    <div class="text-center mb-3">
                      <span class="round-1">59.67%</span>
                    </div>
                    <div className="progress" style={{height: "15px"}}>
                      <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: "50%"}}></div>
                    </div>
                      
                  </div>
                  <div className="col-md-6 co-lg-6">
                      <h5 className="gradient_text">Hybrid FST Token Details</h5>
                      <table className="table sm_table">
                      <tbody>
                          <tr>
                            <td>Current Supply</td>
                            <td>0 FST</td>
                          </tr>
                          <tr>
                            <td>Swapping Supply</td>
                            <td>211985.5311 FST</td>
                          </tr>
                          <tr>
                            <td>Total Supply</td>
                            <td>720,000</td>
                          </tr>
                          <tr>
                            <td>FST in Circulation</td>
                            <td>0 FST</td>
                          </tr>
                          <tr>
                            <td>Burned FST</td>
                            <td>2600 FST</td>
                          </tr>
                      </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
          {/* modal */}
          <div className="modal fade" id="Token-Distribution-Chart">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
          
                <div className="modal-header">
                  <h4 className="modal-title">Firsttron Token Distribution Chart</h4>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
          
                <div className="modal-body">
                  <p className="text-success">Token Symbol: FST, Total Supply: 720,000 only.</p>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>DISTRIBUTION PHASE</th>
                          <th>QUANTITY IN FST</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Phase 1</th>
                          <td>1,00,000</td>
                          <td>Running</td>
                        </tr>
                        <tr>
                          <th >Phase 2</th>
                          <td>Community Approval</td>
                          <td>Community voting</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="modal_footer_bg">
                  * No upcoming Phases has been decided<br />* Next all phases will be decided through community voting &amp; Approval under Firsttron Governance System
                </div>
          
              </div>
            </div>
          </div>
          {/* modal */}
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="all_heading text-center">
              <h2><span>Your Referral</span> link</h2>
            </div>
            <div class="referal_inner text-center">
              <h4 class="word-break">https://firsttron.com/TSJWxnuXzg.....HCNRw</h4>
              <button title="copy Link" class="grad_btn">Copy Link</button>
            </div>
          </div>
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="sm_container">
              <div className="Package_inner">
                <h4><strong>Tip!</strong></h4>
                <hr />
                <p className="mb-0">Cryptocurrency are all on the moon mission now, IMP HYBRID is an opportunity for the new year. IMP HYBRID price depends on top 20 cryptocurrency, the altcoin season has began, HODL your IMP and take maximum benifit.</p>
              </div>
            </div>
          </div>
        </section>

       
        </Frame>
      );
    }
  }

  export default Contact;