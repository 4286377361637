import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header>
          <div className="container">
            <div className="header_box">
              <nav className="navbar navbar-expand-md d-flex justify-content-between">
                <a className="navbar-brand" href="/">
                  <img className="header_logo" src="img/logo.png" alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                  <i className="fa fa-bars"></i>
                </button>
              
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      {this.props.isHome ? (
                        <a className="theme_btn" href="/details">Details</a>):(
                        <a className="theme_btn" href="/">Home</a>
                      )}
                    </li>
                  </ul>
                </div>
              </nav> 
            </div>
          </div>
        </header>
      );
    }
  }

  export default Header;