import React, { Component } from 'react';
import Frame from "../../components/frame";
import TronWeb from 'tronweb';
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {userDetailsApi} from '../../utils';

class Details extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        isConnected: false, 
        tronWeb: {
          installed: false,
          loggedIn: false
        },
        userDetails:{
          uplineIncome:0,
          downlineIncome:0,
          totalWithdraw:0,
          sponsorIncome:0,
          totalPackage:0,
          totalIncome:0,
          walletBalance:0
        },
        subscriptionList:null,
        withdrawList:null,
        incomeList:null,
      }
    }

    async componentDidMount(){
      await new Promise(resolve => {
      const tronWebState = {
          installed: !!window.tronWeb,
          loggedIn: window.tronWeb && window.tronWeb.ready
      };
  
      if(tronWebState.installed) {
          this.setState({
              tronWeb:
              tronWebState
          });
  
          return resolve();
      }
  
      let tries = 0;
  
      const timer = setInterval(() => {
          if(tries >= 10) {
              const TRONGRID_API = 'https://api.shasta.trongrid.io';
  
              window.tronWeb = new TronWeb(
                  TRONGRID_API,
                  TRONGRID_API,
                  TRONGRID_API
              );
  
              this.setState({
                  tronWeb: {
                      installed: false,
                      loggedIn: false
                  }
              });
  
              clearInterval(timer);
              return resolve();
          }
  
          tronWebState.installed = !!window.tronWeb;
          tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
  
          if(!tronWebState.installed)
              return tries++;
  
          this.setState({
              tronWeb: tronWebState
          });
  
          resolve();
      }, 100);
      });
  
      if(!this.state.tronWeb.loggedIn) {
          window.tronWeb.on('addressChanged', () => {
              if(this.state.tronWeb.loggedIn)
                  return;
  
              this.setState({
                  tronWeb: {
                      installed: true,
                      loggedIn: true
                  }
              });
          });
      }
      
      this.userDetailsApi();
  
      window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
          this.userDetailsApi();
      });
    }

    paginate = (
      totalItems,
      currentPage = 1,
      pageSize = 10,
      maxPages = 10
    ) => {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
  
      // ensure current page isn't out of range
      if (currentPage < 1) {
          currentPage = 1;
      } else if (currentPage > totalPages) {
          currentPage = totalPages;
      }
  
      let startPage, endPage;
      if (totalPages <= maxPages) {
          // total pages less than max so show all pages
          startPage = 1;
          endPage = totalPages;
      } else {
          // total pages more than max so calculate start and end pages
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
              // current page near the start
              startPage = 1;
              endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
              // current page near the end
              startPage = totalPages - maxPages + 1;
              endPage = totalPages;
          } else {
              // current page somewhere in the middle
              startPage = currentPage - maxPagesBeforeCurrentPage;
              endPage = currentPage + maxPagesAfterCurrentPage;
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
    }

    getPaginationData = async(url,page,type,current_page,last_page) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.userDetailsApi(url,page,type);
    }

    refreshApiData = async() => {
      this.setState({loading:true},()=>{
        this.userDetailsApi();
      });
    }

    userDetailsApi = async(url=null,page=null,type=null)=>{
      if(!window.tronWeb.defaultAddress.base58){
        return false;
      }
      const data = await userDetailsApi(window.tronWeb.defaultAddress.base58,url,page,type);
      //const data = await userDetailsApi('TYmUotFJxMXb79YVqH7ZyDoZ2U1yBDh3co',url,page,type);
      console.log('userDetailsApi',data);
      if(data.status){
        let totalIncome = parseInt(data.data.upline_income) +
        parseInt(data.data.downline_income) + parseInt(data.data.sponsor_income);
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            uplineIncome:data.data.upline_income,
            downlineIncome:data.data.downline_income,
            totalWithdraw:data.data.total_withdraw,
            sponsorIncome:data.data.sponsor_income,
            totalPackage:data.data.totalPackage,
            totalIncome:totalIncome,
            walletBalance:data.data.wallet_balance,
          }})
        );

        let subscriptionList = data.data.subscription_list;
        let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
        subscriptionList.pages = subscriptionListPagination.pages;

        let withdrawList = data.data.withdraw_list;
        let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
        withdrawList.pages = withdrawListPagination.pages;

        this.setState({
          subscriptionList:data.data.subscription_list,
          withdrawList:data.data.withdraw_list,
          incomeList:data.data.income_details,
          loading:false        
        });
      }else{
        // let defaultError = "Some error occurred!";
        // if(data.hasOwnProperty('error')){
        //   defaultError = data.error;
        // }
        // this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    render() {
      let this2 = this;
      return (
        <Frame withHeader={true} withFooter={true} isHome={false}>
          
          {this.state.loading ? (
            <div className="loader-container">
              <div className="linespinner"></div>
            </div>
          ):(null)}

          <section className="pb-5 pt-5 copy_link_bg">
            <div className="container">
              <h2 className="page_title">Details</h2>
            </div>
          </section>

          <section className="pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg1">
                    <h5>Total Bonus</h5>
                    <p>{parseInt(this.state.userDetails.totalIncome).toFixed(2)} TRX</p>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg2">
                    <h5>Sponsor Bonus</h5>
                    <p>{parseInt(this.state.userDetails.sponsorIncome).toFixed(2)} TRX</p>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg3">
                    <h5>Upline Bonus</h5>
                    <p>{parseInt(this.state.userDetails.uplineIncome).toFixed(2)} TRX</p>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg4">
                    <h5>Downline Bonus</h5>
                    <p>{parseInt(this.state.userDetails.downlineIncome).toFixed(2)} TRX</p>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg5">
                    <h5>Wallet Balance</h5>
                    <p>{parseInt(this.state.userDetails.walletBalance).toFixed(2)} TRX</p>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg6">
                    <h5>Your Subscription</h5>
                    <p>{parseInt(this.state.userDetails.totalPackage).toFixed(2)} TRX</p>
                  </div>
                </div>
                {/* <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg7">
                    <h5>Community Level</h5>
                    <p>0.00 TRX</p>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6">
                  <div className="wallet_box bg8">
                    <h5>Affiliate Level</h5>
                    <p>0.00 TRX</p>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <section className="position-relative">
            <div className="container mb-5">
              <div className="main_heading middle_heading mb-5">
                <h2>Subscription History</h2>
                <img src="img/heading_line.png" alt="" />
              </div>
              <div className="banner_bottom_bg teble-box">
                <div className="teble-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th> Total Amount </th>
                        <th> Activation Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subscriptionList ? (
                        this.state.subscriptionList.data.length>0 ? (
                          this.state.subscriptionList.data.map(function(val, index){                        
                            return (
                              <tr key={`pah${index}`}>
                                <td className="text-info">{parseInt(val.price).toFixed(2)} TRX</td>
                                <td>{val.created_at}</td>
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                      )}                    
                    </tbody>
                  </table>
                </div>
                {this.state.subscriptionList ? (
                  <div className="text-center">
                    <ul className="table_pagination mb-0">
                      <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}><i class="fa fa-angle-double-left"></i></a>
                      </li>
                      {this.state.subscriptionList.pages.length>0 ? (
                        this.state.subscriptionList.pages.map(function(val, index){
                          let className = "";
                          if(val == this2.state.subscriptionList.current_page){
                            className = "active";
                          }
                          return (
                            <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                              <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                            </li>
                          )
                        })
                      ):(null)}
                      <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} ><i class="fa fa-angle-double-right"></i></a>
                      </li>
                    </ul>
                  </div>
                ):(null)}
              </div>
            </div>
          </section>

          <section className="pt-5 pb-5 position-relative">
            <div className="container mb-5">
              <div className="main_heading middle_heading mb-5">
                <h2>Withdraw History</h2>
                <img src="img/heading_line.png" alt="" />
              </div>
              <div className="banner_bottom_bg teble-box">
                <div className="teble-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th> # Transaction Id </th>
                        <th> Amount From </th>
                        <th> Withdrawable Amount </th>
                        <th> Reinvestment Amount </th>
                        <th> Remarks </th>
                        <th> Status </th>
                        <th> Date </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.withdrawList ? (
                        this.state.withdrawList.data.length>0 ? (
                          this.state.withdrawList.data.map(function(val, index){                        
                            return (
                              <tr key={`wl${index}`}>
                                <td> <span className="table_span">{val.t_hash}</span> </td>
                                <td className="text-success"> {val.amount_from} {val.coin_type}</td>
                                <td className="text-success"> {parseInt(val.withdrawable_amount).toFixed(2)} {val.coin_type}</td>
                                <td className="text-success"> {parseInt(val.reinvestment_amount).toFixed(2)} {val.coin_type}</td>                                                       
                                <td> {val.remarks} </td>
                                <td className={val.status == 'Approved' ? 'text-success':'text-danger'}> {val.status} </td>
                                <td> {val.created_at} </td>
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                      <tr>
                        <td colSpan="7" className="text-center">No Data Available!</td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {this.state.withdrawList ? (
                    <div className="text-center">
                    <ul className="table_pagination mb-0">
                    <li style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                      <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i class="fa fa-angle-double-left"></i></a>
                    </li>
                    {this.state.withdrawList.pages.length>0 ? (
                      this.state.withdrawList.pages.map(function(val, index){
                        let className = "";
                        if(val == this2.state.withdrawList.current_page){
                          className = "active";
                        }
                        return (
                          <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                            <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)}>{val}</a>
                          </li>
                        )
                      })
                    ):(null)}
                    <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                      <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i class="fa fa-angle-double-right"></i></a>
                    </li>
                  </ul>
                  </div>
                ):(null)}
              </div>
            </div>
          </section>

          <section className="position-relative">
            <div className="container mb-5">
              <div className="main_heading middle_heading mb-5">
                <h2>My sponsor Income</h2>
                <img src="img/heading_line.png" alt="" />
              </div>
              <div className="banner_bottom_bg teble-box">
                <div className="teble-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                      <th> Level </th>
                        <th> Income </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.incomeList ? (
                        <>
                          <tr>
                            <td> 1 </td>
                            <td>{parseFloat(this.state.incomeList.sponsor_level_1).toFixed(2)} TRX</td>
                          </tr>
                          <tr>
                            <td> 2 </td>
                            <td>{parseFloat(this.state.incomeList.sponsor_level_2).toFixed(2)} TRX</td>
                          </tr>
                          <tr>
                            <td> 3 </td>
                            <td>{parseFloat(this.state.incomeList.sponsor_level_3).toFixed(2)} TRX</td>
                          </tr>
                          <tr>
                            <td> 4 </td>
                            <td>{parseFloat(this.state.incomeList.sponsor_level_4).toFixed(2)} TRX</td>
                          </tr>
                          <tr>
                            <td> 5 </td>
                            <td>{parseFloat(this.state.incomeList.sponsor_level_5).toFixed(2)} TRX</td>
                          </tr>
                          <tr>
                            <td> 6 </td>
                            <td>{parseFloat(this.state.incomeList.sponsor_level_6).toFixed(2)} TRX</td>
                          </tr>
                          <tr>
                            <td> 7 </td>
                            <td>{parseFloat(this.state.incomeList.sponsor_level_7).toFixed(2)} TRX</td>
                          </tr>
                        </>
                      ):(
                        <tr>
                          <td colSpan="7" className="text-center">No Data Available!</td>
                        </tr>
                      )}                      
                    </tbody>
                  </table>
                </div>                
              </div>
            </div>
          </section>
                   
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    },
    buttonProgress: {
      //color: "#fff",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  }

  export default withRouter(withStyles(useStyles)(withSnackbar(Details)));
  //export default Details;