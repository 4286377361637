import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import TronWeb from 'tronweb';
import {storeTransactionApi,tokenDetailsApi} from '../../utils';

class TokenDistribution extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      isConnected: false, 
      tronWeb: {
        installed: false,
        loggedIn: false
      },
      tokenDetails:{
        totalSubscription:0,
        totalToken:0,
        claimedToken:0,
        remainingToken:0,
        tokenList:[],
        details:null
      },
      input_address:'',
      loadingStoreTrsn:false,
    }
  }

  async componentDidMount(){
    await new Promise(resolve => {
    const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready
    };

    if(tronWebState.installed) {
        this.setState({
            tronWeb:
            tronWebState
        });

        return resolve();
    }

    let tries = 0;

    const timer = setInterval(() => {
        if(tries >= 10) {
            const TRONGRID_API = 'https://api.shasta.trongrid.io';

            window.tronWeb = new TronWeb(
                TRONGRID_API,
                TRONGRID_API,
                TRONGRID_API
            );

            this.setState({
                tronWeb: {
                    installed: false,
                    loggedIn: false
                }
            });

            clearInterval(timer);
            return resolve();
        }

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;

        if(!tronWebState.installed)
            return tries++;

        this.setState({
            tronWeb: tronWebState
        });

        resolve();
    }, 100);
    });

   

    if(!this.state.tronWeb.loggedIn) {
        window.tronWeb.on('addressChanged', () => {
            if(this.state.tronWeb.loggedIn)
                return;

            this.setState({
                tronWeb: {
                    installed: true,
                    loggedIn: true
                }
            });
        });
    }

    setInterval(()=>{
      //this.fetchData();
    },3000);    

    this.tokenDetailsApi();

    window.tronWeb.on('addressChanged', () => {
        if(this.state.tronWeb.loggedIn)
        //this.fetchData();
        this.tokenDetailsApi();
    });
  }
  
  tokenDetailsApi = async()=>{
    if(!window.tronWeb.defaultAddress.base58){
      return false;
    }
    const data = await tokenDetailsApi(window.tronWeb.defaultAddress.base58);
    //const data = await tokenDetailsApi('TWC8GqmGNkW8m5gYBz1bYKx58qxM8qzdzf');
    //const data = await tokenDetailsApi('TY2QB26WRgiRG7ZR7Yyi2YXgmcBXqXVMVY');
    //console.log('tokenDetailsApi',data);
    if(data.status){      
      this.setState(({tokenDetails})=>
        ({tokenDetails : {...tokenDetails,
          totalSubscription:data.data.totalSubscription,
          totalToken:data.data.totalToken,
          claimedToken:data.data.claimedToken,
          remainingToken:data.data.remainingToken,
          tokenList: data.data.tokenList.data,
          details: data.data.tokenList,
        }})
      );
      this.setState({loading:false});
    }else{
      let defaultError = "Some error occurred!";
      if(data.hasOwnProperty('error')){
        defaultError = data.error;
      }
      this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      this.setState({loading:false});
    }
  }

  doStoreTrsn = async(e) => {
    if(!this.state.input_address){
      this.props.enqueueSnackbar("Address is required!",{ variant: 'error' });
      this.setState({input_addressError:true});
      return false;
    }

    try {
      this.setState({loadingStoreTrsn:true, loading:true});
      const data = await storeTransactionApi(window.tronWeb.defaultAddress.base58,this.state.input_address);
      //const data = await missingTransactionApi(this.state.input_address);
      //console.log('doStoreTrsn',data);
      if(data.status){
        this.setState({input_address:'', loadingStoreTrsn:false, loading:false});
        this.props.enqueueSnackbar(data.message,{ variant: 'success' })
      }else{
        let defaultError = "Transaction failed!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loadingStoreTrsn:false, loading:false});
      }
    } catch (err) {
      console.log('------catch-------');
      console.log(err);
      this.setState({loadingStoreTrsn:false, loading:false});
      let defaultError = 'Some error occurred!';
      this.props.enqueueSnackbar(defaultError,{ variant: 'error' });
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <Frame withHeader={true} withFooter={true} isHome={true}>
        <section className="banner_section pt_50 pb_50">
          <div className="container">
            <div className="banner_text text-center middle_text">
              <h1>FIRSTTRON LAUNCHES <br /> FTINU TOKEN</h1>
            </div>
            <div className="text-center">
              <h6 className="theme_color">Please add FTINU TOKEN in your metamask or trustwallet using following details.</h6>
              <hr style={{backgroundColor:'#ffffff'}} />
              <h5 className="ftinu_token_text">Contract Address: 
                <CopyToClipboard text={`0x3F9b9423c2f1A3A2c2d618Cc087F4551DE126C03`}
                  onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                  <span style={{cursor:'pointer'}}> 0x3F9b9423c2f1A3A2c2d618Cc087F4551DE126C03</span>
                </CopyToClipboard>
              </h5>
              <p className="mb-0">Symbol: FTINU</p>
              <p>Decimal: 8</p>              
            </div>            
          </div>
        </section>

        {this.state.loading ? (
          <div className="loader2_bg">
            <div className="loader2"></div>
          </div>
        ) : (null)}
      
        <section className="pb_50">
          <div className="container">
            <div className="all_heading text-center">
              <h2><span>Token </span> Details</h2>
            </div>
            <div className="row cus_row">
              <div className="col-md-3 col-sm-3 col-6">
                <div className="Package_inner">
                  <h4>Total Package</h4>
                  <h5>{this.state.tokenDetails.totalSubscription}</h5>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6">
                <div className="Package_inner">
                  <h4>Total Token</h4>
                  <h5>{this.state.tokenDetails.totalToken}</h5>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6">
                <div className="Package_inner">
                  <h4>Claimed Token</h4>
                  <h5>{this.state.tokenDetails.claimedToken}</h5>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6">
                <div className="Package_inner">
                  <h4>Available Token</h4>
                  <h5>{this.state.tokenDetails.remainingToken}</h5>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* <section className="pb_50">
          <div className="container">
            <div className="sm_container">
              <div className="row">
                  <div className="col-md-8 col-lg-8 col-sm-8">
                    <div className="form-group">
                      <input className="cus_input" type="text" placeholder="Submit your BNB address to claim" 
                        onChange={e => this.setState({input_address:e.target.value})}
                        value={this.state.input_address}
                        //error={this.state.input_addressError}
                        //name="" 
                      />
                      <p style={{marginTop:5}}>Token will last till 25% of total supply.</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <div className="form-group">
                      <button className="grad_btn btn-block" disabled={this.state.loadingStoreTrsn} onClick={e => this.doStoreTrsn(e)}>Submit</button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section> */}
      
        <section className="pb_50">
          <div className="container">
              <div className="all_heading text-center">
                <h2><span>Previous Claim </span> Details</h2>
              </div>
              <div className="">
                <div className="table_inner">
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>Date </th>
                          <th>Address </th>
                          <th>Transaction ID</th>
                          <th>Amount </th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tokenDetails.tokenList ? (
                          this.state.tokenDetails.tokenList.length>0 ? (
                            this.state.tokenDetails.tokenList.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`detail${index}`} className={class_name}>
                                  <td>{val.created_at}</td>
                                  <td>{val.address}</td>
                                  <td>{val.transaction_id}</td>
                                  <td>{val.amount}</td>
                                  <td>
                                    {val.status == 2 ? "Rejected" : null }
                                    {val.status == 1 ? "Approved" : null }
                                    {val.status == 0 ? "Pending" : null }
                                    {val.status == 5 ? "On Hold" : null }
                                  </td>
                                </tr>
                              )
                            })
                          ):(
                            <tr>
                              <td colSpan="5" className="text-center">No Data Available!</td>
                            </tr>
                          )
                        ) : (
                        <tr>
                          <td colSpan="5" className="text-center">No Data Available!</td>
                        </tr>
                        )} 
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        </section>      
      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  },
  buttonProgress: {
    //color: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(TokenDistribution)));
//export default TokenDistribution;