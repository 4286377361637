import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



import Home from "./pages/home/home";
import Details from "./pages/details/details";
import Ftdiscounttoken from "./pages/ftdiscounttoken/ftdiscounttoken";
import Firsttrontoken from "./pages/firsttrontoken/firsttrontoken";



import './App.css';

class App extends React.Component {

  render() {
  return (
    <div className="App">
        <Router>
      


        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/token_distribution">
            <Ftdiscounttoken></Ftdiscounttoken>
          </Route>
          <Route exact path="/firsttrontoken">
            <Firsttrontoken></Firsttrontoken>
          </Route>
          <Route exact path="/details">
            <Details></Details>
          </Route>
          <Route exact path="/:referral?">
            <Home></Home>
          </Route>
          
        
          
        </Switch>
     
    </Router>
    </div>
  );
  }
}

export default App;
