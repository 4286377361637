const contractAddress = 'THvpNNCoHVGfgacLFWAf8YAEhjrbksQRkN';

export const utils = {

    tronWeb: false,
    contract: false,
    address: contractAddress,
    async setTronWeb(tronWeb) {
        this.tronWeb = tronWeb;
        this.contract = await tronWeb.contract().at(contractAddress)
    },

};

const contractAddressFtinu = 'THUJQy6MPFqp8jidZYBrtdvYyhz7xsdSH6';

export const Ftinu = {
    tronWeb: false,
    contract: false,
    address: contractAddressFtinu,
    async setTronWeb(tronWeb) {
        this.tronWeb = tronWeb;
        this.contract = await tronWeb.contract().at(contractAddressFtinu)
    },
};



export const tronApi = (hash) =>{

    
    const url = 'https://api.trongrid.io/v1/transactions/'+hash+'/events';
    const options = {method: 'GET', headers: {Accept: 'application/json'}};

    return new Promise( (resolve, reject) => {

            const result = {status:false, data:[]}
            setInterval(()=>{
                fetch(url, options)
            .then(res => res.json())
            .then((json)=>{

                if(json.data.length > 0){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
            
            },10000);

    })   

}

export const userDetailsApi = (address,fullUrl,type,page) =>{
    let url = '';
    if(fullUrl){
        url = fullUrl;
    }else{
        url = 'https://tronfactory.io/app/api/user-detail/'+address;
        if(type){
            url = url+'?'+type+'='+page;
        }
    }

    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            //setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const communityDetailsApi = (address) =>{
    const url = 'https://tronfactory.io/app/api/community-detail/'+address;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const withdrawApi = (address,amount,captchaValue,secret) =>{
    const url = 'https://tronfactory.io/app/api/withdrawal';

    var formData = new FormData();
    formData.append('address', address);
    formData.append('amount', amount);
    formData.append('recaptcharesponse', captchaValue);
    formData.append('recaptchasecret', secret);

    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    result.message = json.message;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('amount')){
                        result.error = json.errors['amount'];
                    }
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('address')){
                        result.error = json.errors['address'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('secret')){
                        result.secret = json.secret;
                    }
                    if(json.hasOwnProperty('captcha')){
                        result.captcha = json.captcha;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

export const missingTransactionApi = (t_hash) =>{
    const url = 'https://tronfactory.io/app/api/missing-transaction';
    let params = {
        t_hash:t_hash
    }

    var formData = new FormData();
    formData.append('t_hash', t_hash);
    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
        //body:JSON.stringify(params)
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    result.message = json.message;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('t_hash')){
                        result.error = json.errors['t_hash'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('message')){
                        result.error = json.message;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

export const tokenDetailsApi = (address) =>{
    const url = 'https://tronfactory.io/app/api/token-details/'+address;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const storeTransactionApi = (address,input_address) =>{
    const url = 'https://tronfactory.io/app/api/store-transaction/'+address;
    let params = {
        address:input_address
    }

    var formData = new FormData();
    formData.append('address', input_address);
    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
        //body:JSON.stringify(params)
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    result.message = json.message;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('address')){
                        result.error = json.errors['address'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}
