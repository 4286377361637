import React, { Component } from 'react';
import { utils as Utils, Ftinu } from '../utils';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer>
          <div className="footer__wrapper">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-md-3 col-lg-3">
                  <img className="footer_logo" src="img/logo.png" alt="" />
                </div>
                <div className="col-md-9 col-lg-9">
                  <div className="footer_text">
                    <h5>Contract: <a href={`https://tronscan.org/#/contract/${Utils.address}`} target={'_blank'}>{Utils.address.slice(0, 10) + '.....' + Utils.address.slice(Utils.address.length-5, Utils.address.length)}</a></h5>
                    <p className="mb-0">Tronfactory is the most promising platform for Tron investment, and the platform connects you to millions of investors across the globe. We closely track the global crypto ecosystem to provide you with a decentralized, safe, transparent, and universally accessible financial ecosystem developed only for crypto enthusiasts.</p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div className="footer__copyright">
            <div className="container">
              <p>© 2023 tronfactory All Rights Reserved. </p>
            </div>
          </div>
        </footer>
      );
    }
  }

  export default Footer;